import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { CaptureUtils } from 'src/app/pages/capture/capture-files/capture-utils';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { IWorkflow, IWorkflowActivity } from './models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class CaptureReviewService {

  private logger: Logger;
  private httpErrorResponse: HttpErrorResponse;
  public workflow: IWorkflow;
  public workflowList: Array<IWorkflow>;

  constructor(
    private device: Device,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private platform: Platform,
  ) {
    this.logger = loggingService.getLogger("[CaptureReviewService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
   }

   /*
  	 * shows Image in ImageReview Edit Control
  	 *
  	 * @param - ImageId
  	 */

   public showImageReviewControl(imageId, successCallBack, errorCallBack) {
    const ImageReviewControl = window['kfxCordova'].kfxUicontrols.createImageReviewControl();
    console.log('imageId', imageId);
    let Layout = window['kfxCordova'].getLayoutProperties();
		// this.dialogService.showLoadingSpinnerDialog('showImageReviewControl()');
    // window.plugins.spinnerDialog.show(null, "Loading....", true);
    //Calculate layout of ImageReviewControl
    var yPos = document.getElementById("header").offsetHeight - 2;
    //Height of the status bar in iOS
    var STATUSBARHEIGHT_iOS = 20;
    //This variable holds height of the content(i.e. ImageReviewControl)to be increased to fit in the available area
    var heightOffset = -4;

    var height =
      document.getElementById("previewfooter").offsetHeight + document.getElementById("header").offsetHeight;
    if (parseInt(this.device.version) >= 7 && this.platform.is('ios')) {
      yPos = yPos + STATUSBARHEIGHT_iOS;
      //   height = height+(parseInt(device.version) < 11? STATUSBARHEIGHT_iOS : 0);
      height = height + STATUSBARHEIGHT_iOS;
    }

    // if (CaptureUtils.hasNotch() && this.platform.is('ios')) {
      // var footerElement = document.getElementsByClassName("previewfooter")[0];
      // footerElement.style.marginBottom = "20px";
    // }
    Layout.x = 0;
    Layout.y = yPos;
    // Layout.width = $(window).width();
    // Layout.height = $(window).height() - (height + heightOffset);
    Layout.visibility = true;

    ImageReviewControl.addImageReviewEditView(
      (data) => {
        console.log("calling success kfxCordova.ImageReviewEditView.addImageReviewEditView ");
        ImageReviewControl.setImage(
          function (data) {
		        // this.dialogService.hidLoadingSpinnerDialog();
            successCallBack();
            console.log("calling success kfxCordova.ImageReviewEditView.setImage from app.js");
          },
          function (data) {
            console.table(data);
		        // this.dialogService.hidLoadingSpinnerDialog();
            errorCallBack();
          },
          imageId
        );
      },
      (data) => {
		    // this.dialogService.hidLoadingSpinnerDialog();
        alert("Error addImageReviewEditView! " + JSON.stringify(data));
        errorCallBack();
      },
      Layout
    );
  }

  public setImage(imageId, successcallback, errorcallback) {
    const ImageReviewControl = window['kfxCordova'].kfxUicontrols.createImageReviewControl();
    console.log("calling setimage from app.js");

    ImageReviewControl.setImage(
      function (data) {
        successcallback(data);
        console.log("calling success ImageReviewEditView.removeView");
      },
      function (error) {
        errorcallback(error);
        alert("Error ImageReviewControl.removeView! " + error);
      },
      imageId
    );
  }

  /*
   * Hides ImageReview Edit Control view
   *
   */

  public removeImageReview() {
    const ImageReviewControl = window['kfxCordova'].kfxUicontrols.createImageReviewControl();
    console.log("calling hideCapturedImage from app.js");

    ImageReviewControl.removeView(
      function (data) {
        console.log("calling success ImageReviewEditView.removeView");
      },
      function (error) {
        alert("Error ImageReviewControl.removeView! " + error);
      }
    );
  }

  // FROM REVIEW SCREEN HELPER
  /*
	 * shows Image in ImageReview Edit Control
	 *
	 * @param - ImageId
	 */

	public initializeImageReviewControlScreenEvents(useCallBack, retakeCallBack) {
		// $("#preview_retake").click(function (e) {
		// 	retakeCallBack(true);
		// });

		// $("#preview_use").one("click", function () {
		// 	useCallBack();
		// });

		// $("#previewclose").click(function (e) {
		// 	retakeCallBack(false);
		// });
	}
}
