import { CUSTOM_ELEMENTS_SCHEMA, NgModule, PipeTransform, Pipe, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { PrinterByPrinterSelectedTruePipePipe } from './pipes/printer-by-printer-selected-true-pipe.pipe';
// import { PrinterTypePipePipe } from './pipes/printer-type-pipe.pipe';

// import { PrintJobByPrinterPipe } from './pipes/print-job-by-printer.pipe';
// import { StringularService } from './pipes/stringular.pipe';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { ErrorNetworkComponent } from './components/error-network/error-network.component';
import { PopoverComponent } from './components/popover/popover.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CreateTranslateLoader, LanguageService } from './services/language/language.service';
import { NetworkInterceptor } from './services/network/network.interceptor';
import { DefaultHeadersInterceptor } from './services/http/default-headers.interceptor';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { SecurePrintService } from './services/secure-print/secure-print.service';
import { Broadcaster } from './services/events/broadcaster.class';
import { ReleaseResourceService } from './services/release-resource/release-resource.service';
import { PrintJobService } from './services/print-job/print-job.service';
import { PagingService } from './services/paging/paging.service';
import { PrinterService } from './services/printer/printer.service';
import { TenantService } from './services/tenant/tenant.service';
import { UserService } from './services/user/user.service';
import { AuthService } from './services/auth/auth.service';
import { StorageService } from './services/storage/storage.service';
import { ErrorService } from './services/error/error.service';
import { IntuneMamService } from './services/intune-mam/intune-mam.service';
import { NetworkService } from './services/network/network.service';
import { DialogService } from './services/dialog/dialog.service';
import { Subject } from 'rxjs';
import { PasswordService } from './services/password/password.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { EventService } from './services/events/event.service';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Market } from '@ionic-native/market/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx/';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { DeviceService } from './services/device/device.service';
import { Network } from '@ionic-native/network/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx'
import { Device } from '@ionic-native/device/ngx';
import { VersionControlService } from './services/version-control/version-control.service';
import { ReleaseHistoryService } from './services/release-history/release-history.service';
import { SendLogsService } from './services/logs/send-logs.service';
import { HttpService } from './services/http/http.service';
import { LogoutService } from './services/logout/logout.service';
import { LoginService } from './services/login/login.service';
import { PlatformService } from './services/platform/platform.service';
import { ReleasePrintService } from './services/release-print/release-print.service';
import { NfcService } from './services/nfc/nfc.service';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import { DatePipe } from '@angular/common';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { HttpErrorModalComponent } from './components/http-error-modal/http-error-modal.component';
import { LogsService } from './services/logs/logs.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { OrderModule } from 'ngx-order-pipe';
import { LoggingServiceModule, LoggingService } from "ionic-logging-service";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { LocalStorageModule } from 'angular-2-local-storage';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { PipesModule } from './pipes/pipes.module';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { MomentModule } from 'ngx-moment';
import { EnvironmentService } from './services/environment/environment.service';
import { logging_config } from './logging-config/logging-config';
import { environment } from '../environments/environment';
import { CaptureHistoryService } from './services/capture-history/capture-history.service';
import { CaptureService } from './services/capture/capture.service';
import { CaptureReviewService } from './services/capture/capture-review.service';
import { CaptureProcessorService } from './services/capture/capture-processor.service';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';

export function configureLogging(loggingService: LoggingService): () => void {
  return () => loggingService.configure(logging_config);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorNetworkComponent,
    HttpErrorModalComponent,
    MenuComponent,
    ModalComponent,
    PopoverComponent,
    SpinnerComponent,
    // ********* PIPES *********************************** //
    // PrinterByPrinterSelectedTruePipePipe,
    // PrintJobByPrinterPipe,
    // PrinterTypePipePipe,
    // OrderByPipe,
    // StringularService,
    // ********* PAGES *********************************** //
    // NetworkCheckPage
  ],
  // entryComponents: [
  //   MenuComponent,
  //   NetworkCheckPage,
  //   ErrorNetworkComponent,
  //   ReleaseHistoryPage,
  //   TenantSelectPage,
  //   PrintJobsPage,
  //   RegisterNfcTagPage,
  //   PrinterListPage,
  //   GoPage,
  //   PopoverComponent,
  //   ModalComponent,
  //   HttpErrorModalComponent,
  //   SpinnerComponent,
  // ],
  imports: [
    // SharedModule,
    LoggingServiceModule,
    BrowserModule,
    IonicModule.forRoot(),
    // IonicModule.forRoot({_forceStatusbarPadding: true}),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    OrderModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    LocalStorageModule.forRoot({
      prefix: 'ls',
      storageType: 'localStorage'
    }),
    PipesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (CreateTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  exports: [
    TranslateModule,
    PipesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
			deps: [LoggingService],
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: configureLogging,
		},
    SpinnerDialog,
    UserService,
    EmailComposer,
    Camera,
    AndroidPermissions,
    Flashlight,
    Clipboard,
    // StringularService,
    // Zip,
    Diagnostic,
    EnvironmentService,
    EventService,
    File,
    DatePipe,
    File,
    Ndef,
    NFC,
    NfcService,
    PlatformService,
    ReleasePrintService,
    SpinnerDialog,
    UserService,
    // StringularService,
    // Zip,
    // FormValidationService,
    LoginService,
    LogoutService,
    HttpService,
    SendLogsService,
    LogsService,
    ReleaseHistoryService,
    CaptureHistoryService,
    VersionControlService,
    Device,
    DeviceService,
    InAppBrowser,
    QRScanner,
    Market,
    Deeplinks,
    // SplashScreen,
    StatusBar,
    Vibration,
    SQLite,
    Network,
    SafariViewController,
    NavParams,
    Keyboard,
    ScreenOrientation,
    PasswordService,
    // NotificationService,
    AuthService,
    Broadcaster,
    DialogService,
    ErrorService,
    NetworkService,
    IntuneMamService,
    LanguageService,
    PagingService,
    PrintJobService,
    CaptureService,
    CaptureReviewService,
    CaptureProcessorService,
    PrinterService,
    ReleaseResourceService,
    SecurePrintService,
    Subject,
    StorageService,
    TenantService,
    TranslateService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }