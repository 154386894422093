<ion-content>
  <!-- printJob action button popup -->
  <ion-list *ngIf="menuType === 'printJobMenu'">
    <button class="print-job-action-button"
      (click)="itemClick(printJob, null, 'showPrintJobInfo')">
      <i class="icon-info mr10 ml10"></i>
      {{'Info' | translate}}
    </button>
    <button class="print-job-action-button"
      (click)="itemClick(printJob, null, 'deletePrintJob')">
      <i class="icon-delete mr10 ml10"></i>
      {{'Delete' | translate}}
    </button>
  </ion-list>

  <!-- select favorite printer list popup -->
    <div class="printqueues-list-button-container" *ngIf="menuType === 'select-printer-menu'">
      <ion-button large class="btn printqueues-list-button" (click)="itemClick(null, null, 'showPrintQueuesListPage')">{{"OpenPrintersPage" | translate}}</ion-button>
    </div>
    <ion-list *ngIf="menuType === 'select-printer-menu'">
      <ion-item lines="none" class="printer-list-item" *ngFor="let printer of printerList">
        <ion-list>
          <ion-item lines="none" class="printer-queue-container" *ngFor="let printQueue of printer.queues" [ngClass]="{'last-used-queue' : printQueue.isLastUsedQueue}">
            <button class="select-printer-clickzone printer-queue" [ngClass]="{'queue-disabled' : printQueue.isDisabled}" (click)="itemClick(printQueue, printer, 'print')">
              <div class="printer-id printer-queue printer-id--online pull-left"
                [ngClass]="{'adjust-signId' : printer.duplexSupport || printer.colorSupport}">
                <div>{{printQueue.signId}}</div>
                <div class="printer-features">
                  <div ng-show="printer.duplexSupport || printer.colorSupport">
                    <img class="printer-feature__icon printer-feature--color"
                    title="{{'Color print' | translate}}"
                    *ngIf="printer.colorSupport"
                    src="https://assets.printix.net/img/v2/icons/printer-features/cmyk.svg"
                    alt="CMYK icon">

                    <img class="printer-feature__icon printer-feature--duplex"
                    title="{{'Two-sided print' | translate}}"
                    *ngIf="printer.duplexSupport"
                    src="https://assets.printix.net/img/v2/icons/printer-features/duplex.svg"
                    alt="Duplex icon">
                  </div>
                </div>
              </div>
            </button>
            <button class="select-printer-clickzone printer-queue text" (click)="itemClick(printQueue, printer, 'print')">
              <div class="printer-in-popover pull-left">
                <div class="queue-name">{{printQueue.name}} </div>
                <div class="printer-location" *ngIf="printer.location">{{printer.location}}</div>
              </div>
            </button>
            <button class="favorite-printer-clickzone printer-info" tappable (click)="itemClick(printQueue, printer, 'printerInfo')" >
              <span class="favorite-printer-icon">
                <i class="favorite-color info-icon">i</i>
              </span>
            </button>
            <button tappable class="favorite-printer-clickzone" (click)="toggleFavoritePrinterQueue(printQueue, printer)">
              <span class="favorite-printer-icon">
                <i class="favorite-color" [ngClass]="{'icon-favorite': !printQueue.isFavorite,
                'icon-favorite-solid': printQueue.isFavorite}"></i>
              </span>
            </button>
          </ion-item>
        </ion-list>
      </ion-item>
    </ion-list>

  <!-- FilterPrinterList -->
  <ion-list class="filter-select" *ngIf="menuType === 'filterPrinterList'">
    <ion-item lines="none" (click)="showNetworkList()">{{"Network" | translate}}
        <i class="icon-angle-down"></i>
    </ion-item>
    <ion-item lines="none" (click)="clearAllFilters()">{{"ClearAllFilters" | translate}}</ion-item>
    <ion-item lines="none" (click)="dismiss(null)">{{"Cancel" | translate}}</ion-item>
  </ion-list>

  <!-- deselect printJobs -->
  <div *ngIf="showDeselectPrintJobs">
    <div class="deselect-text">
        <h5>{{"UnavailableDocuments" | translate}}</h5>
        <p>{{"DeselectUnavailableDocuments" | translate}}</p>
    </div>
    <div class="deselect-buttons">
      <ion-button (click)="dismiss(null)">{{'Cancel' | translate}}</ion-button>
      <ion-button (click)="dismiss('deselectPrintJobs')">{{'Ok' | translate}}</ion-button>
    </div>
  </div>

  <!-- show secure print level (for web) -->
  <div *ngIf="showSecurePrintLevel">
    <div class="secure-print-level-text">
      <h5>{{"SecurePrintLevel" | translate }}</h5>
      <h5 *ngIf="securePrintLevel === 'QR'">{{"SecurePrintLevel1" | translate}}</h5>
      <h5 *ngIf="securePrintLevel === 'NFC'">{{"SecurePrintLevel2" | translate}}</h5>
      <p>{{"MustUseAppToPrint" | translate | stringular: "Printix"}}</p>
    </div>
    <div class="secure-print-level-button">
      <ion-button (click)="dismiss(null)">{{'Close' | translate}}</ion-button>
    </div>
  </div>

  <!-- show on device printer message - user must sign in at printer -->
  <div *ngIf="showMustSignInAtPrinter">
    <div class="secure-print-level-text">
      <h5>{{"SecurePrintLevel" | translate}}</h5>
      <h5>{{"MustSignInAtPrinter" | translate}}</h5>
      <p>{{"MustUseGoToPrint" | translate | stringular: "Printix"}}</p>
    </div>
    <div class="secure-print-level-button">
      <ion-button (click)="dismiss(null)">{{'Close' | translate}}</ion-button>
    </div>
  </div>

  <!-- show unmatched printer message -->
  <div *ngIf="showUnmatchedPrinterMessage">
    <div class="unmatched-printer-text">
      <h5 *ngIf="printJobs.length === 1">{{"InvalidPrintJobSelected" | translate}}</h5>
      <h5 *ngIf="printJobs.length !== 1">{{"InvalidPrintJobsSelected" | translate}}</h5>
    </div>
    <div class="unmatched-printer-button">
      <ion-button (click)="dismiss('unMatchedPrinter')">{{'Close' | translate}}</ion-button>
    </div>
  </div>

  <!-- remove unavailable printJobs and print matching printJobs (after scan) -->
  <div *ngIf="menuType === 'releaseMatchingPrintJobs'">
    <div class="deselect-text">
        <h5>{{"UnavailableDocuments" | translate}}</h5>
        <p>{{"PrintAvailableDocuments" | translate}}</p>
    </div>
    <div class="deselect-buttons">
      <ion-button (click)="dismiss('cancelRelease')">{{'Cancel' | translate}}</ion-button>
      <ion-button (click)="dismiss('releaseMatchingPrintJobs')">{{'Ok' | translate}}</ion-button>
    </div>
  </div>

  <!-- Airprint password -->
  <div *ngIf="showAirPrintPasswordsList">
    <div class="airprint-popover">
      <div class="airprint-popover__header">
        <h5 class="airprint-popover__header__title">{{'AirPrintPassword' | translate}}</h5>
      </div>
      <div class="airprint-popover__content">
        <ion-item class="airprint-popover__content__generated-password">
          <ion-input readonly type="url">{{generatedAirPrintPassword}}</ion-input>
          <!-- <ion-input type="url" value="{{generatedAirPrintPassword}}"></ion-input> -->
        </ion-item>
      </div>
    </div>
    <div class="close-btn-container">
      <ion-button (click)="dismiss(null)">{{'Close' | translate}}</ion-button>
      <ion-button (click)="copyText($event)">{{'CCopy' | translate}}</ion-button>
    </div>
  </div>

  <!-- select secure print methods popover -->
  <ion-list *ngIf="showSecurePrintMethods">
    <h5>{{'PrinterIdMethod' | translate}}</h5>
    <ion-label *ngIf="securePrintLevel !== 'ON_DEVICE_RELEASE' && securePrintLevel !== null">{{"SecurePrintLevelN" | translate | stringular: securePrintLevel}}</ion-label>
    <ion-label *ngIf="securePrintLevel !== 'ON_DEVICE_RELEASE' && securePrintLevel === null">{{"SecurePrintLevelN" | translate | stringular: '0'}}</ion-label>
    <div class="nfc-not-available" *ngIf="!nfcEnabled && securePrintLevel === 'NFC'">{{"NFC not available on this evice" | translate}}</div>
    <div class="secure-print-method-list">
      <ion-item *ngFor="let method of activeMethods">
          <i *ngIf="method['name'] === 'QR'" class="icon-qr-code-2"></i>
          <i *ngIf="method['name'] === 'NFC'" class="icon-nfc-chip"></i>
          <ion-label *ngIf="method['name'] === 'QR'" class="network-name">{{'QRCode' | translate}}</ion-label>
          <ion-label *ngIf="method['name'] === 'NFC'" class="network-name">{{'NfcTag' | translate}}</ion-label>
          <ion-label *ngIf="securePrintLevel === 'ON_DEVICE_RELEASE'" class="network-name">{{'MustUseGoToPrint' | translate}}</ion-label>
          <ion-checkbox class="network-checkbox" [(ngModel)]="method['checked']" *ngIf="method['name'] !== 'ON_DEVICE_RELEASE'">{{method['name']}}</ion-checkbox>
      </ion-item>
    </div>
    <div class="action-buttons" *ngIf="securePrintLevel !== 'ON_DEVICE_RELEASE'">
      <ion-button (click)="dismiss()">{{'Cancel' | translate}}</ion-button>
      <ion-button (click)="saveSecurePrintMethods()">{{'Ok' | translate}}</ion-button>
    </div>
    <div class="action-buttons close-button" *ngIf="securePrintLevel === 'ON_DEVICE_RELEASE'">
      <ion-button (click)="dismiss()">{{'Close' | translate}}</ion-button>
    </div>
  </ion-list>

  <!-- show secure scanning methods buttons in bottom action bar -->
  <ion-list *ngIf="showScanningMethods">
    <ion-item *ngFor="let method of securePrintMethods" lines="none">
      <ion-button class="print__print-btn btn" [disabled]="checkActiveSecureLevel(method)" (click)="dismiss(method)">
        <span *ngIf="method === 'QR'">
          <i class="icon-qr-code-2"> </i>
          <span class="print__print-btn--method-text ml5">{{'ScanQR' | translate}}</span>
        </span>
        <span *ngIf="method === 'NFC'">
          <i class="icon-nfc-chip"></i> {{'ScanNfcTag' | translate}}
        </span>
      </ion-button>
    </ion-item>
  </ion-list>

  <!-- show send logs dialog -->
   <div class="email-logs-button-container" *ngIf="showSendLogsDialog">
     <ion-button *ngIf="showSendLogsDialog" size="default" (click)="dismissSendLogsPopover('SendLogsWithEmail', null)">
       {{'EmailLogs' | translate}}
       </ion-button>
   </div>

  <!-- show support send logs to server ID user input -->
  <div *ngIf="showPromptForSupportID">
    <form #sendLogsForm="ngForm" (ngSubmit)="dismissSendLogsPopover('SendLogsToServer', supportID)">
      <div class="id-prompt-popover">
        <div class="id-prompt-popover__header">
          <h5 class="id-prompt-popover__header__title">{{'SupportRequest' | translate}}</h5>
        </div>
        <div class="id-prompt-popover__content">
          <ion-item lines="none" class="id-prompt-popover__content__input">
            <ion-input type="n" [(ngModel)]="supportID" name="sendLogsInput" placeholder="{{'EnterNumber' | translate}}" type="text" required></ion-input>
          </ion-item>
              <div class="id-prompt-popover__content__validation" *ngIf="!sendLogsForm.valid && sendLogsForm.controls.sendLogsInput?.touched">
                  {{'EnterNumber' | translate}}
                </div>
        </div>
      </div>
      <div class="close-btn-container">
        <ion-button (click)="dismiss(null)">{{'Cancel' | translate}}</ion-button>
        <ion-button type="submit" [disabled]="!sendLogsForm.valid">{{'Send' | translate}}</ion-button>
      </div>
    </form>
  </div>

  <!-- show nfc scanning ready message for android -->
  <ion-list *ngIf="showNfcScanningReady">
    <ion-item lines="none">
      <ion-label>{{'ReadyToScan' | translate}}</ion-label>
    </ion-item>
    <ion-button class="btn" (click)="dismiss('cancel')">{{'Cancel' | translate}}</ion-button>
  </ion-list>

  <!-- show confirm printer and tag for registering -->
  <ion-list *ngIf="confirmPrinterWithTag">
    <ion-item lines="none">
      <ion-label>{{ 'RegisterWithPrinter' | translate | stringular: PrinterSignId}}</ion-label>
    </ion-item>
    <ion-button (click)="dismiss()">{{'Cancel' | translate}}</ion-button>
    <ion-button class="fl-right" (click)="dismiss('registerTag')">{{'Ok' | translate}}</ion-button>
  </ion-list>

  <!-- show device version warning -->
  <ion-list *ngIf="showDeviceVersionWarning">
    <ion-item lines="none">
      <ion-label>{{ 'NewVersionIsAvailable' | translate }}</ion-label>
    </ion-item>
    <ion-item lines="none" tappable class="go-to-store-link" *ngIf="platform.is('android')">
      <img src="./assets/market-links/google-play-badge.png" alt="google-play-store-link" (click)="dismiss('goToStore')">
    </ion-item>
    <ion-item lines="none" tappable class="go-to-store-link" *ngIf="platform.is('ios')">
      <img src="./assets/market-links/apple-app-store-badge.png" alt="apple-app-store-link" (click)="dismiss('goToStore')">
    </ion-item>
    <ion-button class="mt20" (click)="dismiss('cancel')">{{'Close' | translate}}</ion-button>
  </ion-list>

  <!-- show release history filters -->
  <!-- <ion-list *ngIf="releaseHistoryFilters">
    <ion-label class="networks-list-title">{{"ReleaseHistory" | translate}}{{"Filters" | translate}}</ion-label>
    <div class="network-list-container">
      <ion-item *ngFor="let filter of releaseHistoryFilters">
        <ion-label class="network-name">{{filter.translatedText | translate}}</ion-label>
        <ion-checkbox class="network-checkbox" [(ngModel)]="filter.checked" (ionChange)="toggleFilter(filter)">{{filter.name}}</ion-checkbox>
      </ion-item>
    </div>
  </ion-list> -->
</ion-content>
