import { ChangeDetectorRef, Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { CHANNELS } from 'src/app/services/events/event.service';
import { PagingService } from 'src/app/services/paging/paging.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { IPrintJobRelease } from 'src/app/services/release-history/models/IPrintJobRelease.model';
import { IPrintJobReleaseResource } from 'src/app/services/release-history/models/IPrintJobReleaseResource.model';
import { ReleaseHistoryService } from 'src/app/services/release-history/release-history.service';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Router } from '@angular/router';
import { NetworkService } from 'src/app/services/network/network.service';

@Component({
  selector: 'app-release-history',
  templateUrl: './release-history.page.html',
  styleUrls: ['./release-history.page.scss'],
})
export class ReleaseHistoryPage implements OnInit {

  //   private releaseHistoryFilters: Array<object> = [];
  private acceptedReleaseStates: Array<string> = ['RELEASED', 'TIMEOUT', 'FINISHED'];
  public appIsActive = true;
  private createdReleaseSubscription: Subscription = null;
  public currentLanguage: string = null;
  private currentNetworksPage: number = 0;
  public currentPage: number = 0;
  public dataIsLoaded: boolean = false;
  private deletedReleaseSubscription: Subscription = null;
  public platformResumeEventSubscription: Subscription = null;
  public languageChangeSubscription: Subscription = null;
  private logger: Logger;
  private matchingQueues: string = null;
  public myInput;
  public nav: any;
  public pagingObject: any = {};
  private pageSize: number = 5;
  private previousReleaseVersion: number = 0;
  private previousSearchString: string = null;
  public printJobReleases: Array<IPrintJobRelease> = null;
  private printJobReleaseResource: IPrintJobReleaseResource = null;
  private printerParameters: any;
  private printerUrlString: string;
  private refresher: any = null;
  private resourceId: string = null;
  private resourceFilters: Array<string> = null;
  private searchString: string = null;
  public  signInMethods: {};
  public tenant: ITenant = null;
  public getTenantAndUserSubscription: Subject<void> = new Subject<void>();
  private unSubscribe: Subject<void> = new Subject<void>();
  private unSubscribePage: Subject<boolean> = new Subject<boolean>();
  private updatedReleaseSubscription: Subscription = null;
  private urlString: string;
  public  user: IUser = null;
  public  isInternetAccessAvailable = true;
  public isInternetAvailableSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    private modalCtrl: ModalController,
    private navController: NavController,
    private networkService: NetworkService,
    private pagingService: PagingService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private printerService: PrinterService,
    private releaseHistoryService: ReleaseHistoryService,
    private router: Router,
    private storageService: StorageService,
    private tenantService: TenantService,
    public  translateModule: TranslateModule,
    public translateService: TranslateService,
    private userService: UserService
  ) {
    this.logger = loggingService.getLogger("[ReleaseHistoryPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.tenant = this.tenantService.tenant;
    this.user = this.userService.getCurrentUser();
    this.signInMethods = {
      LOCAL_AD: 'AD',
      AZURE: 'Microsoft',
      EMAIL: 'Email',
      GOOGLE: 'Google',
      OKTA: 'Okta',
      ONELOGIN: 'OneLogin',
      VERTICAL: 'Vertical',
      client_credentials: 'API',
      KIOSK: 'Kiosk',
      OIDC: 'OIDC'
    };

    this.platform.pause.subscribe(async () => {
      this.appIsActive = false;
    });

    this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
      this.appIsActive = true;
      if (this.isCurrentUrlThisPage()) {
        this.refreshReleaseHistory();
      }
    });

    // this.releaseHistoryFilters = [
    //     {
    //         name: '12 Hours',
    //         translatedText: '12Horus',
    //         checked: true
    //     },
    //     {
    //         name: '4 Days',
    //         translatedText: '4Days',
    //         checked: false
    //     },
    //     {
    //         name: 'Always',
    //         translatedText: '4Ever',
    //         checked: false
    //     }
    // ];
  }

  ngOnInit() {

  }

  private isCurrentUrlThisPage() {
    let isThisPage = false;
    let currentUrl = null;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    currentUrl = urlTree.toString();
    if (currentUrl && currentUrl === '/release-history') {
      isThisPage = true;
    };
    return isThisPage;
  }

  public refreshReleaseHistory(refresher?) {
    this.logger.info('refreshReleaseHistory()');
    this.refresher = refresher;
    this.dataIsLoaded = false;
    this.getReleaseHistory();
  }

  public getReleaseHistory() {
    this.logger.info('getReleaseHistory()');
      let currentUserUrl: string = this.tenant.links.currentUser;
      this.dialogService.showLoadingSpinnerDialog('getReleaseHistory()').subscribe(() => {
      this.releaseHistoryService.getPrintJobReleaseUrl(currentUserUrl)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((printJobReleaseUrl: string) => {
          this.releaseHistoryService.getReleaseHistory(printJobReleaseUrl, this.currentPage, this.pageSize, 'CREATED', 'DESC')
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((printJobReleaseResource: IPrintJobReleaseResource) => {
            this.dialogService.hideLoadingSpinnerDialog('getReleaseHistory()');
            this.pagingObject = printJobReleaseResource['page'];
            this.printJobReleases = printJobReleaseResource['printJobReleases'];
            // console.log('this.printJobReleases', this.printJobReleases);
            this.dataIsLoaded = true;
            if (this.refresher) {
              this.refresher.target.complete();
            }

            this.unSubscribe.next();
            this.unSubscribe.complete();
            this.handleReleaseJobStatus();
          }, (error) => {
              this.dataIsLoaded = true;
              if (this.refresher) {
                  this.refresher.target.complete();
                }
              this.dialogService.hideLoadingSpinnerDialog('ERROR getReleaseHistory()');
          });
      }, (error) => {
          this.dataIsLoaded = true;
          if (this.refresher) {
              this.refresher.target.complete();
            }
          this.dialogService.hideLoadingSpinnerDialog('ERROR getReleaseHistory() - getPrintJobReleaseUrl');
      });
    });
  }

  public async presentInfoModal(event, target, modalType): Promise<void> {

    let dataPackage: any = {
      target: target.releaseJob,
      printer: target,
      releaseJobStatus: target.releaseJobStatus,
      cssClass: modalType
    };

    const infoModal = await this.modalCtrl.create({
      component: ModalComponent,
      componentProps: {dataPackage},
      cssClass: modalType
    });

    return await infoModal.present();
  }

  private handleReleaseJobStatus() {
    this.logger.info('handleReleaseJobStatus()');
      if (this.printJobReleases && this.printJobReleases.length > 0) {
        this.printJobReleases.forEach((PJRelease, releaseIndex) => {
            PJRelease.releaseJobs.forEach((releaseJob, jobIndex) => {
                if (releaseJob['releaseJobStatus'] === 'SUCCESS') {
                    this.logger.info('releaseJob["releaseJobStatus"]' + releaseJob['releaseJobStatus']);
                } else {
                    if (releaseJob['releaseJobStatus']['releaseProblem']) {
                      this.logger.info('handleReleaseJobStatus() ' + releaseJob["releaseJobStatus"]["releaseProblem"]);
                    } else {

                    }
                }
            });
        });
      }
    }
    // public presentPopover(myEvent, popoverType: string, data): void {
    //     if(this.dataIsLoaded) {

    //         let dataPackage: any = {
    //         type: popoverType,
    //         releaseHistoryFilters: data
    //         };

    //         let popover = this.popoverCtrl.create(PopoverComponent, dataPackage, {cssClass: popoverType});

    //         popover.present({ ev: myEvent });

    //         popover.onDidDismiss((filters) => {
    //             console.log('popoverType', popoverType);
    //             console.log('filters', filters);

    //         });
    //     }
    // }

    public getNextPage(showPrevious?) {
        if (showPrevious) {
          this.currentPage--;
        } else {
          this.currentPage++;
        }
        this.refreshReleaseHistory();
    }

    // private printerHasWarnings(printer: IPrinter): boolean { // VIEW
    //     return this.printerService.printerHasWarnings(printer);
    // }

    // private printerHasErrors(printer: IPrinter): boolean { // VIEW
    //   return this.printerService.printerHasErrors(printer);
    // }

    // private printerHasProxy(printer: IPrinter): boolean {
    //   return this.printerService.printerHasProxy(printer);
    // }

    // private printerNotAvailable(printer: IPrinter): boolean {
    //   return this.printerService.printerNotAvailable(printer);
    // }

    private startListeners(): void {
    this.logger.info('startListeners()');
        if (!this.createdReleaseSubscription) {
            this.createdReleaseSubscription = this.broadcaster.on<string>(CHANNELS.SPECIFIC_USER.EVENTS.USER_RELEASE_CREATED)
            .subscribe((data) => {
                // check state to see if page needs refresh
                console.log('**PUSHER release CREATED', 'release history', data);
                this.logger.info('**PUSHER release CREATED');
                this.acceptedReleaseStates.forEach(state => {
                    if (data['state'] === state && this.appIsActive && this.isCurrentUrlThisPage()) {
                        this.refreshReleaseHistory();
                    }
                });
            });
        }
        if (!this.updatedReleaseSubscription) {
          this.updatedReleaseSubscription = this.broadcaster.on<string>(CHANNELS.SPECIFIC_USER.EVENTS.USER_RELEASE_UPDATED)
          .subscribe((data) => {
            // check newState param to see if release
            console.log('**PUSHER release UPDATED', 'release history', data);
            this.logger.info('**PUSHER release UPDATED');
            this.handleReleaseUpdate(data);
          });
        }

        if (!this.deletedReleaseSubscription) {
            this.deletedReleaseSubscription = this.broadcaster.on<string>(CHANNELS.SPECIFIC_USER.EVENTS.USER_RELEASE_DELETED)
            .subscribe((data) => {
                // remove the release from releases array
                console.log('**PUSHER release DELETED', 'release history', data);
                this.logger.info('**PUSHER release DELETED');
                let deletedReleaseIndex = this.findReleaseIndex(data['release']);

                if (deletedReleaseIndex !== -1 && this.appIsActive && this.isCurrentUrlThisPage()) {
                    this.refreshReleaseHistory();
                }
            });
        }

        this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
        .subscribe((isInternetAccessAvailable: boolean) => {
          this.isInternetAccessAvailable = isInternetAccessAvailable;
          this.changeDetectorRef.detectChanges();
          console.log('isInternetAccessAvailable', isInternetAccessAvailable);
        });

        this.languageChangeSubscription = this.broadcaster.on<string>('languageChange')
        .subscribe(data => {
          console.log('changeLanguage data', data);
          this.currentLanguage = data === 'no' ? 'nb' : data;
        });
    }

    private handleReleaseUpdate(data: string) {
        this.logger.info('handleReleaseUpdate()');
        // console.log('data["releaseJobStates"]', data["releaseJobStates"]);
        // console.log('data["stateChange"]', data["stateChange"]);

        // this.printJobReleases.forEach(release => {
        //     release.state = 'BLOMMER';
        //     release.releaseJobs.forEach(job => {
        //         job.releaseJobStatus['jobState'] = 'BJÆÆLDER';
        //     });
        // });

        // is there a change of state for the release //
        let stateChange: any = data['stateChange']['value'] ? data['stateChange']['value'] : null;
        let stateBefore: string = stateChange ? stateChange['stateBefore'] : null;
        let stateAfter: string = stateChange ? stateChange['stateAfter'] : null;

        // console.log('stateChange', stateChange);

        if (stateChange && stateBefore === 'CREATING' && this.appIsActive && this.isCurrentUrlThisPage()) { // is there a new release to see //
            this.refreshReleaseHistory();
        } else {
            let releaseIndex = this.findReleaseIndex(data['release']);
            // console.log('releaseIndex', releaseIndex);
            if (releaseIndex !== null) { // check if the upated release are in the currrent view //
                let releaseToUpdate = this.printJobReleases[releaseIndex];
                let updatedJobStates = data['releaseJobStates'];
                let currentReleaseVersion: number = data['version'];

                if (currentReleaseVersion > releaseToUpdate.version) {
                    releaseToUpdate.version = currentReleaseVersion;

                    releaseToUpdate.state = stateAfter;
                    // console.log('releaseToUpdate', releaseToUpdate);
                    releaseToUpdate.releaseJobs.forEach(job => {
                        // console.log('release to update jobs job', job);
                        // console.log('updatedJobStates', updatedJobStates);
                        updatedJobStates.forEach(jobState => {
                            let linkArray = jobState.job.split('/');
                            let updatedJobId = linkArray[linkArray.length -1];
                            // console.log('job.jobId', job.jobId);
                            // console.log('updatedJobId', updatedJobId);
                            if (job.jobId === updatedJobId) {
                                // console.log('jobState', jobState);
                                // console.log('job', job);
                                job.releaseJobStatus['inProgress'] =  jobState['inProgress'];
                                job.releaseJobStatus['isFailed'] =  jobState['isFailed'];
                                job.releaseJobStatus['isFinished'] =  jobState['isFinished'];
                                job.releaseJobStatus['jobState'] = jobState.jobState;
                            }
                        });
                    });
                } else {
                    console.log('## VERSION OUT OF ORDER');
                }
                // console.log('currentReleaseVersion', currentReleaseVersion);
            }
        }
    }

    private findReleaseIndex(releaseUrl: any): number {
        this.logger.info('findReleaseIndex()');
        let releaseIndex: number = null;
        if (this.printJobReleases && this.printJobReleases.length !== 0) {
            this.printJobReleases.forEach(release => {
                if (release.links.self === releaseUrl) {
                    releaseIndex = this.printJobReleases.indexOf(release);
                }
            });
        }
        return releaseIndex;
    }

    // private updatePageTitle() {
    //     this.broadcaster.broadcast('update title', 'History');
    // }

    ionViewDidEnter(): void {
        this.storageService.getItemFromKeyValueTable('lastUsedTenant')
      .pipe(takeUntil(this.getTenantAndUserSubscription))
      .subscribe((tenant: ITenantLite) => {
        this.logger.info('ionViewDidEnter() got last used tenant');
            if (tenant) {
              this.tenantService.refreshCurrentTenant(tenant.links.self)
              .pipe(takeUntil(this.getTenantAndUserSubscription))
              .subscribe((tenant) => {
                this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
                .pipe(takeUntil(this.getTenantAndUserSubscription))
                .subscribe((user) => {
                  this.user = user;
                  this.tenant = tenant;
                  this.getTenantAndUserSubscription.next();
                  this.getTenantAndUserSubscription.complete();
                  if (this.user && this.user.embedded.user.language) {
                    this.currentLanguage = this.user.embedded.user.language === 'no' ? 'nb' : this.user.embedded.user.language;
                  }
                  this.startListeners();
                  this.getReleaseHistory();
                });
              });
            } else {
              this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
            }
        });
    }

  ionViewDidLeave(): void {
    this.createdReleaseSubscription.unsubscribe();
    this.deletedReleaseSubscription.unsubscribe();
    this.isInternetAvailableSubscription.unsubscribe();
    this.languageChangeSubscription.unsubscribe();
    this.platformResumeEventSubscription.unsubscribe();
    this.updatedReleaseSubscription.unsubscribe();
  }
}
