<ion-content force-overscroll="false" [ngClass]="{'transparentBody': showTransparentBody}">
  <app-header
    [iconName]="'menu'"
    [nav]="nav"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true"
    [showErrorMessageHealth]="true"
    [allowHeartDisplay]="true"
    [hidden]="showTransparentBody">
  </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>

  <div class="overlay" *ngIf="overlay"></div>

  <div [ngClass]="{'none' : showTransparentBody}">

    <div class="go-content pl20 pr20">

      <!-- <div class="mt20" *ngIf="!gettingPinCode && !gettingIdCode"> -->
      <div class="mt20 code-section">

        <div style="border-bottom: 1px solid #ccc">
            <span *ngIf="pinCodeResource || !idCodeResource" >{{'PinCode' | translate}}</span>
            <span *ngIf="idCodeResource && idCodeResource.pincodeRequired && !pinCodeResource" class="color-danger">{{'PinCodeRequired' | translate}}</span>
          <button class="pretty-button__small fl-right" *ngIf="!gettingPinCode && pinCodeResource && !enterPinCode" (click)="toggleEnterPinCode()">
            {{'Reset' | translate}}
          </button>
          <button class="pretty-button__small fl-right" *ngIf="!gettingPinCode && !pinCodeResource && !enterPinCode" (click)="toggleEnterPinCode()">
            {{'Create' | translate}}
          </button>
        </div>
        <div class="pt10 pb10">
          <span *ngIf="pinCodeResource && pinCodeResource.disabled" class="color-danger">{{'PinCodeDisabled' | translate}}</span>
          <span *ngIf="pinCodeConflict" class="color-danger">{{'PinCodeConflict' | translate}}<i class="icon-close fl-right pseudo-link" (click)="pinCodeConflict = false"></i></span>
          <div class="password-dot__container" *ngIf="!gettingPinCode && pinCodeResource && !enterPinCode && !pinCodeResource.disabled && !pinCodeConflict">
            <div class="password-dot"></div>
            <div class="password-dot"></div>
            <div class="password-dot"></div>
            <div class="password-dot"></div>
          </div>
          <i class="icon-delete fl-right mr5 pseudo-link" *ngIf="!gettingPinCode && pinCodeResource && !enterPinCode && !pinCodeConflict" (click)="deletePinCode()"></i>
          <ion-spinner name="lines" *ngIf="gettingPinCode"></ion-spinner>
          <span *ngIf="!gettingPinCode && !pinCodeResource && !enterPinCode">-</span>

          <div *ngIf="enterPinCode">
            <form (ngSubmit)="setPinCode()">
              <div>
                <input
                class="pretty-input"
                name="setPinCodeInput"
                type="text"
                maxlength="4"
                placeholder="{{'EnterPinCode' | translate}}"
                [ngClass]="{'input-warning' : invalidPinCode}"
                (ngModelChange)="checkPinCode($event)"
                [(ngModel)]="newPinCode">
                <i class="icon-close fl-right pseudo-link" (click)="toggleEnterPinCode()"></i>
              </div>
              <div class="pt10">
                <button type="submit" class="btn btn-primary" [disabled]="incompletePinCode || invalidPinCode">
                  {{'Save' | translate  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div class="mt20" *ngIf="!gettingPinCode && !gettingIdCode"> -->
      <div class="mt20 code-section">
        <div style="border-bottom: 1px solid #ccc">
          <span>{{'IdCode' | translate}}</span>
          <button class="pretty-button__small fl-right size80" (click)="resetIdCode()" *ngIf="idCodeResource">
            {{'Reset' | translate}}
          </button>
          <button class="pretty-button__small fl-right" (click)="setIdCode()" *ngIf="!idCodeResource">
            {{'Create' | translate}}
          </button>
        </div>

        <div class="pt10 pb10">
          <span *ngIf="idCodeResource">
            {{idCodeResource.idCode}}
          </span>
          <!-- <ion-spinner name="lines" [hidden]="loginMethod !== 'ms'"></ion-spinner> -->
          <ion-spinner name="lines" *ngIf="gettingIdCode"></ion-spinner>
          <span *ngIf="!gettingIdCode && !idCodeResource">-</span>
          <i class="icon-delete fl-right mr5 pseudo-link" *ngIf="idCodeResource" (click)="deleteIdCode()"></i>
        </div>
      </div>

      <div class="mt20">
        <div style="border-bottom: 1px solid #ccc">
          {{'Cards' | translate}}
          <button class="pretty-button__small fl-right" *ngIf="!enterCardCode" (click)="registerCardDialog()">
            {{'RegisterCard' | translate}}
          </button>
        </div>
        <form (ngSubmit)="registerCard()">
          <div class="pt10 pb10 register-card" style="border-bottom: 1px solid #ccc" *ngIf="enterCardCode">
            <input
            class="pretty-input"
            type="text"
            name="registerCardInput"
            maxlength="6"
            placeholder="{{'EnterCode' | translate}}"
            [ngClass]="{'input-warning' : invalidCardCode}"
            (ngModelChange)="checkCardCode($event)"
            [(ngModel)]="cardCode"
            autofocus>
            <i class="icon-close fl-right pseudo-link" (click)="registerCardDialog()"></i>
            <div class="pt20">
              <button type="submit" class="btn btn-primary" [disabled]="incompleteCardCode || invalidCardCode">
                {{'Save' | translate}}
              </button>
            </div>

            <button class="btn btn-primary scan-qr-button" type="button" (click)="openQRScanner()" *ngIf="isCordova">
              {{'Scan' | translate}}
              <i class="icon-qr-code-2"></i>
            </button>
          </div>
        </form>
        <div *ngFor="let card of cards" class="pt5 pb5">
          {{card.created | amDateFormat :"YYYY-MM-DD"}}
          <!-- {{card.created | date:' dd/MM - yyyy'}} -->
          <i class="icon-delete fl-right mr5 pseudo-link" (click)="deleteCard(card)"></i></div>
        </div>
    </div>
  </div>

  <div class="qr-overlay-container" *ngIf="showTransparentBody">
    <button class="stop-qr-scanner" [ngClass]="{'ios': platformIsIos}" (click)="cancelQRScanner()">
      <i class="icon-close"></i>
    </button>
    <div class="qr-focus" [ngClass]="{'qr-warning': invalidQRCode}">
      <div class="qr-msg-container">
        <div class="size70" *ngIf="!invalidQRCode">{{'ScanQR' | translate}}</div>
        <div class="size70" *ngIf="invalidQRCode">{{'InvalidQRCode' | translate}}</div>
        <div class="size120"><i class="icon-qr-code-2"></i></div>
      </div>
    </div>
  </div>
</ion-content>
