import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';
import { Platform } from '@ionic/angular';
import { ErrorService } from '../error/error.service';
import { Broadcaster } from '../events/broadcaster.class';
import { ILanguageItem } from './models/language.model';
import { delay, finalize, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Logger, LoggingService } from 'ionic-logging-service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private storageKeyUserSelectedLanguage: string = 'userLanguage';
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private loggingService: LoggingService,
    private platform: Platform,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private broadCaster: Broadcaster
  ) {
    this.logger = loggingService.getLogger("[LanguageService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.setListeners();
   }

  private extractLanguageKey(languageKey: string): string {
    this.logger.info('extractLanguageKey()');
    let languageKeyToUse: string = '';
    if (languageKey.split('_').length > 1) {
      languageKeyToUse = languageKey.split('_')[0];
    } else if (languageKey.split('-').length > 1) {
      languageKeyToUse = languageKey.split('-')[0];
      console.log('languageKeyToUse', languageKeyToUse);
      if (languageKeyToUse === 'zh') { // For chinese languages
        let chineseCode = (languageKey.split('-')[1]).toLowerCase();
        languageKeyToUse = languageKeyToUse + '-' + chineseCode;
      }
    } else {
      languageKeyToUse = languageKey;
    }
    return languageKeyToUse;
  }

  private setTranslationData(): void {
    this.logger.info('setTranslationData()');
    this.storageService.getItemFromKeyValueTable(this.storageKeyUserSelectedLanguage, 'setTranslationData()')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((userSelectedLanguage: string) => {
      if (userSelectedLanguage) {
        this.translateService.use(userSelectedLanguage);
        // this.platform.setLang(userSelectedLanguage, true);
      } else {
        let defaultLanguage = 'en';
        this.translateService.setDefaultLang(defaultLanguage);
        let deviceLanguage = navigator.language;
        let languageKeyToUse: string = this.extractLanguageKey(deviceLanguage);
        this.translateService.use(languageKeyToUse);
        // this.platform.setLang(languageKeyToUse, true);
      }
      this.unSubscribe.next();
      this.unSubscribe.complete();
    }, (error: any) => {
      this.logger.info('setTranslationData() ERROR: ' + JSON.stringify(error));
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  public getLanguages(): Observable<Array<ILanguageItem>> {
    this.logger.info('getLanguages()');
    let endpointLanguagesSupported = 'https://assets.printix.net/captions/Captions.json';
    return new Observable((observer) => {
      this.httpClient.get<any>(endpointLanguagesSupported)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getLanguages() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[LanguageService] getLanguages()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((languageItems: any) => {
        let languageItemsList: Array<ILanguageItem> = [];
        for (let languageItem of languageItems.Languages) {
          languageItemsList.push({
            codeLanguage: languageItem.LanguageCode,
            codeFlag: languageItem.LanguageCode,
            nameEnglish: languageItem.EnglishName,
            nameNative: languageItem.NativeName
          });
        }
        observer.next(languageItemsList);
        observer.complete();
      });
    });
  }

  public getCurrentLanguageCode(): string {
    return this.translateService.currentLang;
  }

  public changeLanguage(languageKey: string): void {
    this.logger.info('changeLanguage(), languageKey: ' + languageKey);
    if (languageKey) {
      let languageKeyToUse: string = this.extractLanguageKey(languageKey);
      this.translateService.use(languageKeyToUse);
      this.storageService.addItemToKeyValueTable(this.storageKeyUserSelectedLanguage, languageKeyToUse, 'changeLanguage()')
      .subscribe(() => {
        this.translateService.setDefaultLang(languageKeyToUse);
        this.broadCaster.broadcast('languageChange', languageKey); // Broadcast to change language dynamically for moment.js plugin
      });
    }
  }

  private setListeners(): void {
    this.platform.ready().then(() => {
      this.setTranslationData();
    });
  }
}

export function CreateTranslateLoader(httpClient: HttpClient) {
  let endpointLanguages = 'https://assets.printix.net/captions/Caption_App_';
  return new TranslateHttpLoader(httpClient, endpointLanguages, '.json');
}
