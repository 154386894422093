import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { LogsService } from 'src/app/services/logs/logs.service';

import { IPrintJob } from 'src/app/services/print-job/models/print-job.model';
import { PrintJobService } from 'src/app/services/print-job/print-job.service';
import { IPrinter } from 'src/app/services/printer/models/printer.model';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { ReleaseHistoryService } from 'src/app/services/release-history/release-history.service';
import { ReleaseResourceService } from 'src/app/services/release-resource/release-resource.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})

export class ModalComponent implements OnInit {

  public cloudStorage: boolean = false;
  public currentLanguage: string = null;
  public currentNetworksPage: number = 0;
  public dataPackage = {};
  public filterObjectsKey: string = 'filter_objects';
  public gettingPrinter: boolean = false;
  public gettingPrintRulesSettings = false;
  public jobName: string = null;
  private logger: Logger;
  public networkDirection: string = 'ASC';
  public printRuleForcedBw = false;
  public printRuleForcedDuplex = false;
  public printRulesApplied = false;
  public networkFiltersToAdd: Array<any> = [];
  public networksEndpoint = this.tenantService.tenant.links.networks;
  public networkSort: string = 'NAME';
  public networksPageSize: number = 5;
  public pageCount: string = null;
  public pagingObject: any;
  public previousSearchString: string = null;
  public printer = null;
  public printerAccessError: boolean = false;
  public printJob = null;
  public releaseJobStatus = null;
  public resourceId: string = null;
  public searchString: string = null;
  public selectPrintQueue: boolean = false;
  public showJobs: boolean = false;
  public searchInput: string;
  public showNetworksList: boolean = false;
  public showPrinterInfo: boolean = false;
  public showPrintJobInfo: boolean = false;
  public storedNetworks: Array<any> = [];
  public submitTime: string = null;
  public tenantNetworks: Array<any> = [];
  public unSubscribe: Subject<boolean> = new Subject<boolean>();
  public workstationName: string;

  constructor(
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private logsService: LogsService,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public platform: Platform,
    public printerService: PrinterService,
    public printJobService: PrintJobService,
    private releaseHistoryService: ReleaseHistoryService,
    private releaseResourceService: ReleaseResourceService,
    private storageService: StorageService,
    public tenantService: TenantService,
    public userService: UserService
  ) {
    this.logger = loggingService.getLogger("[ModalComponent]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit() {
    this.startModal();
  }

  private startModal() {
    if (this.navParams) {
      const dataPackage = this.navParams.get('dataPackage');
      let modalType = dataPackage.cssClass;
      this.logger.info('startModal() modalType: ' + modalType);
      if (modalType === 'showPrintJobInfo') {
        this.printJob = dataPackage['target'];
        this.releaseJobStatus = dataPackage['releaseJobStatus'];
        if (this.printJob && this.printJob.cloudStoreReferences.length !== 0) {this.cloudStorage = true;}
        this.getworkstationName(this.printJob);
      } else if (modalType === 'printerInfo') {
        this.showPrinterInfo = true;
        this.printer = dataPackage['printer'];
        if (this.printer && this.printer.type !== 'FREEDOM') {
          this.updatePrinter(this.printer);
        }
      } else if (modalType === 'selectPrintQueue') {
        this.selectPrintQueue = true;
        this.printer = dataPackage.target;
        this.logger.info('startModal() Available queues on printer: ' + this.printer.queues.length);
        this.updatePrinter(this.printer);
      } else if (modalType === 'networksList') {
        this.showNetworksList = true;
        this.resourceId = dataPackage.resourceId;
        this.storedNetworks = dataPackage.storedNetworks;
        let networks = dataPackage.networks;
        let pagingObjectIndex = networks.length - 1;
        this.pagingObject = networks[pagingObjectIndex]['page'];
        networks.splice(pagingObjectIndex, 1);
        this.addStoredNetworks();
        this.createFilterObjects(networks);
      }
    }

    this.currentLanguage = this.userService.user.embedded.user.language === 'no' ? 'nb' : this.userService.user.embedded.user.language;
  }

  // ############### NETWORK FILTERS ###############################################################################

  // ## Create filter Objects ## //
  private createFilterObjects(networks)  {
    this.logger.info('createFilterObjects()');
    for (let i = 0; i < networks.length; i++) {
      let storedNetwork: Array<any> = this.networkFiltersToAdd.filter(nw => nw.name === networks[i].name);
      let network: Object = {
        name: networks[i].name,
        id: networks[i].links.self,
        checked: storedNetwork.length === 1 ? true : false
      };
      this.tenantNetworks.push(network);
    }
  }

  private addStoredNetworks(): void {
    this.logger.info('addStoredNetworks()');
    this.storedNetworks.forEach(nw => {
      this.networkFiltersToAdd.push(nw);
    });
  }

  // ## Network toggled ## //
  public toggleNetwork(network): void {
    this.logger.info('toggleNetwork()');
    if (network.checked) {
      this.networkFiltersToAdd.push(network);
    } else {
      this.networkFiltersToAdd = this.networkFiltersToAdd.filter(nwf => nwf.id !== network.id);
    }
    this.updateFilterToDB();
  }

  private updateFilterToDB() {
    this.logger.info('updateFilterToDB()');
    let filtersToAdd: Array<string> = [];
    this.networkFiltersToAdd.forEach(nw => {
      filtersToAdd.push(nw.id);
    });
    this.storageService.addItemToKeyValueTable(this.filterObjectsKey, this.networkFiltersToAdd, 'modalComponent-updateFilterToDB')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      this.unSubscribe.next();
      this.unSubscribe.complete();
      this.broadcaster.broadcast('updatePrinterList');
    });
  }

  // ## get current page networks ## //
  private getTenantNetworks(networks: string, currentPage: number, pageSize: number, sort: string, direction: string, searchInput: string, airPrinter: any): void {
    this.logger.info('getTenantNetworks()');
    this.tenantService.getTenantNetworks(networks, [], currentPage, pageSize, sort, direction, searchInput, airPrinter)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((networksList) => {
      if (networksList.length > 1) {
        let pagingObjectIndex: number = networksList.length -1;
        this.pagingObject = networksList[pagingObjectIndex]['page'];
        networksList.splice(pagingObjectIndex, 1);
        this.tenantNetworks = [];
        this.createFilterObjects(networksList);
      } else {
        this.pagingObject = null;
        this.tenantNetworks = [];
      }
      this.unSubscribe.next();
      this.unSubscribe.complete();
    }, (error: any) => {
      this.unSubscribe.complete();
    });
  }

  // ## Pagingation ## //
  public getNextPage(showPrevious?) {
    this.logger.info('getNextPage()');
    if (showPrevious) {
      this.currentNetworksPage--;
    } else {
      this.currentNetworksPage++;
    }
    this.getTenantNetworks(this.networksEndpoint, this.currentNetworksPage, this.networksPageSize, this.networkSort, this.networkDirection, this.searchString, null);
  }

  // ## Search ## //
  public onSearch(event, searchInput) {
    // console.log('searchInput', searchInput);
    this.logger.info('(click) onSearch()');
    this.searchString = searchInput;
    this.getTenantNetworks(this.networksEndpoint, 0, this.networksPageSize, this.networkSort, this.networkDirection, this.searchString, null);
  }

  // public inputChange() {
  //   console.log('## INPUT_TEXT');
  // }

  // public inputInput(event, searchInput) {
  //   console.log('event', event);
  //   console.log('event.target', event.target.value);
  //   console.log('searchInput', searchInput);
  //   console.log('## INPUT_INPUT');
  // }

  // ############### /NETWORK FILTERS ###############################################################################

  private getworkstationName(printJob): void {
    this.logger.info('getworkstationName()');
    let workstationUrl: string = printJob.links.workstation;
    console.log('printJob', printJob);
    console.log('workstationUrl', workstationUrl);
    if (workstationUrl) {
      this.dialogService.showLoadingSpinnerDialog('ModalComponent getWorkstation()').subscribe(() => {
        this.printJobService.getWorkstation(workstationUrl)
        .subscribe((workstation) => {
          this.dialogService.hideLoadingSpinnerDialog('ModalComponent getWorkstation');
          this.workstationName = workstation.name;
          this.showPrintJobInfo = true;
          this.setBasicPrintRulesSettings();
        }, (error) => {
          this.dialogService.hideLoadingSpinnerDialog('ModalComponent getWorkstation ERROR');
          this.workstationName = error;
          this.showPrintJobInfo = true;
          this.setBasicPrintRulesSettings();
        });
      });
    } else {
      this.showPrintJobInfo = true;
      this.setBasicPrintRulesSettings();
    }
  }

  private setBasicPrintRulesSettings() {
    if (this.releaseJobStatus) {
      this.gettingPrintRulesSettings = true;
      this.releaseHistoryService.getBasicPrintRulesSettings(this.releaseJobStatus)
      .subscribe((basicPrintRules) => {
        this.gettingPrintRulesSettings = false;
        this.printRuleForcedBw = basicPrintRules['printRuleForcedBw'] ? basicPrintRules['printRuleForcedBw'] : null;
        this.printRuleForcedDuplex = basicPrintRules['printRuleForcedDuplex'] ? basicPrintRules['printRuleForcedDuplex'] : null;
      });
    }
  }

  private updatePrinter(printer) {
    this.logger.info('updatePrinter()');
    if (printer) {
      this.gettingPrinter = true;
      this.printerService.refreshPrinterStatus(printer)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((updatedPrinter) => {
        this.logsService.logPrinterInfo(updatedPrinter);
        this.printerAccessError = false;
        this.printer = updatedPrinter;
        this.gettingPrinter = false;
        this.unSubscribe.next();
        this.unSubscribe.complete();
      }, (error) => {
        this.logger.info('updatePrinter() last known printerInfo');
        this.logsService.logPrinterInfo(this.printer);
        this.printerAccessError = true;
        this.gettingPrinter = false;
      });
    }
  }

  public dismiss(returnData?): void {
    this.modalCtrl.dismiss(returnData);
  }

  public print(queue) {
    this.logger.info('print()');
    let modalData: Object = {
      printer: this.printer,
      printJobs: this.printer.printJobs,
      queue: queue
    };
    this.modalCtrl.dismiss(modalData);
  }
}
