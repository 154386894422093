import { Component, OnInit, ViewChild } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorModalComponent } from './components/http-error-modal/http-error-modal.component';
import { NetworkCheckPage } from './pages/auth/network-check/network-check.page';
import { DialogService } from './services/dialog/dialog.service';
import { Broadcaster } from './services/events/broadcaster.class';
import { PlatformService } from './services/platform/platform.service';
import { Logger, LoggingService } from "ionic-logging-service";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Router } from '@angular/router';
import { LogoutService } from './services/logout/logout.service';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  // @ViewChild(Navigator) nav: Navigator;
  // public rootPage: any = NetworkCheckPage;
  public content: any;
  private errorModal: any = null;
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  public nav: any;
  private statusBarColor: string = '#000000';
  public showIpadSpinner = false;

  constructor(
    private broadcaster: Broadcaster,
    private translateService: TranslateService,
    private file: File,
    private device: Device,
    private dialogService: DialogService,
    private logoutService: LogoutService,
    private modalCtrl: ModalController,
    private navController: NavController,
    private nfc: NFC,
    private platform: Platform,
    private platformService: PlatformService,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private loggingService: LoggingService,
    private statusBar: StatusBar
  ) {
    this.platform.ready().then(() => {
      this.logger = loggingService.getLogger("[AppComponent]");
      const methodName = "ctor";
      this.logger.entry(methodName);
      let isHybrid = this.platform.is('hybrid');
      this.logger.info(methodName + 'platform isHybrid: ' + isHybrid);
      this.setupApp();
      this.logger.exit(methodName);
    });
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');
    this.broadcaster.on('ipad_spinner_show').subscribe((securePrintLevel: string) => {
      this.showIpadSpinner = true;
    });
    this.broadcaster.on('ipad_spinner_hide').subscribe((securePrintLevel: string) => {
      this.showIpadSpinner = false;
    });
  }

  private setupStatusBar(): void {
    this.statusBar.styleLightContent();
    this.statusBar.hide();
    this.statusBar.show();
    this.statusBar.overlaysWebView(false);
    if (this.platform.is("ios")) {
      this.statusBar.backgroundColorByHexString(this.statusBarColor);
    }
    setTimeout(() => {
      // this.statusBar.overlaysWebView(false);
    }, 1000)
  }

  private lockScreenOrientation() {
    this.screenOrientation.lock('portrait');
  }

  private checkCurrentDevice() {
    const methodName = 'checkCurrentDevice() ';
    this.logger.info(methodName + 'Device model: ' + this.device.model);
    this.logger.info(methodName + 'Device platform: ' + this.device.platform);
    this.logger.info(methodName + 'Device version: ' + this.device.version);
  }

  public setupErrorRedirect(): void {
    this.logger.info('setupErrorRedirect()');

    this.broadcaster.on('logOutUser').subscribe(navParams => {
      let urlTree = this.router.parseUrl(this.router.url);
      this.logger.info('Current URL: ' + urlTree.toString());
      this.logoutService.logOutUnAuthenticatedUser(navParams);
    });

    this.broadcaster.on('showErrorModal').subscribe(errorObject => {
      this.showHttpErrorModal(errorObject);
    });
  }

  private async showHttpErrorModal(errorObject: any): Promise<void> {
    this.logger.info('showHttpErrorModal()');
    if (this.errorModal === null) {
      let modalType: string = 'http-error-modal';
      this.errorModal = await this.modalCtrl.create({
        component: HttpErrorModalComponent,
        componentProps: {errorObject},
        cssClass: modalType,
        backdropDismiss: false
      });

      await this.errorModal.present();
    }

    this.errorModal.onDidDismiss(modalData => {
      this.errorModal = null;
    });
  }

  private setPreventNFCListener() {
    const nfcReaderFlags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;

    this.nfc.readerMode(nfcReaderFlags).subscribe(
      (tag) => {
          console.log(tag);
      },
      (err) => {
          console.log('Error reading tag', err);
      }
    );
  }

  private setupApp(): void {
    if (this.isCordova) {
      // this.dialogService.hideSplashScreen();
      this.setupStatusBar();
      this.lockScreenOrientation();
      this.checkCurrentDevice();
      if (this.platform.is('android')) {
        this.setPreventNFCListener();
      }
    }
    this.setupErrorRedirect();
  }
}