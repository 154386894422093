<ion-content padding no-bounce text-center>
  <app-header
    [iconName]="'menu'"
    [nav]="nav"
    [title]="null"
    [menuToggleRight]="true"
    [showErrorMessageNetwork]="true"
    [showErrorMessageHealth]="true"
    [allowHeartDisplay]="true">
  </app-header>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
        <i class="icon-warning"></i>
        <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <!-- Refresher -->
  <ion-refresher (ionRefresh)="refreshCaptureHistory($event)" slot="fixed">
    <!-- <ion-refresher-content></ion-refresher-content> -->
  </ion-refresher>
  <div class="top-content">
    <!-- Greetings -->
    <div class="greetings-container">
      <div class="greetings" *ngIf="user && user.name">
        <div *ngIf="user.embedded.authentication_details && user.embedded.authentication_details.source">
          <span ng-class="{'hide-gretting': path !== '/capture'}">{{'SignedInWithAs'| translate | stringular: user.name
            :
            (signInMethods[user.embedded.authentication_details.source] | translate)}}</span>
        </div>
        <div *ngIf="!user.embedded.authentication_details || !user.embedded.authentication_details.source">
          <span ng-class="{'hide-gretting': path !== '/capture'}">{{'SignedInAs'| translate | stringular:
            user.name}}</span>
        </div>
      </div>
      <div class="greetings" *ngIf="tenant && tenant.tenantData.hostingDomain">
        <span ng-class="{'hide-gretting': path !== '/capture'}">{{tenant.tenantData.hostingDomain}}</span>
      </div>
    </div>
    <!-- Buttons -->
    <div class="action-button-container" *ngIf="dataIsLoaded">
      <div class="release-history-action-buttons">
        <ion-title class="release-history__title" *ngIf="">{{'CaptureHistory' | translate}}</ion-title>
        <button class="size120 page-action-button refresh-button" icon-only large item-end
          (click)="refreshCaptureHistory()">
          <i class="icon-refresh"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- Scans -->
  <div class="print__content pt0 pr20 pb100 pl20">
    <!-- No releases -->
    <div *ngIf="(scanResources.length === 0) && dataIsLoaded">
      <h2 class="empty-state__heading ng-binding">
        {{'NoRecentReleases' | translate}}
      </h2>
    </div>
    <!-- ScanJobs -->
    <div class="list list--boxed list--spaced" *ngIf="scanResources.length > 0">
      <div class="list-row" *ngFor="let scanResource of scanResources">
        <span class="list__item-title">
          {{scanResource.workflowName}}
        </span>
        <div class="list__item-description">
          <div>
            <div [ngSwitch]="scanResource.destinationType">
              <span *ngSwitchCase="'EMAIL'">{{"Email" | translate}}</span>
              <span *ngSwitchCase="'ONEDRIVE'">{{"MicrosoftOneDrive" |
                translate}}</span>
              <span *ngSwitchCase="'SHAREPOINT'">{{"MicrosoftSharePoint" |
                translate}}</span>
              <span *ngSwitchCase="'Connector'">{{"Connector" | translate}}</span>
              <span *ngSwitchDefault>{{"UnknownScanDestination" | translate}}</span>
            </div>
            <div [ngSwitch]="scanResource.state">
              <span *ngSwitchCase="'FINISHED'">{{"CaptureSucceeded" | translate}}</span>
              <span class="importantState" *ngSwitchCase="'FAILED'">{{"CaptureFailed" | translate}}</span>
              <span *ngSwitchCase="'RUNNING'">{{"CaptureInProgress" | translate}}</span>
            </div>
            <div [ngSwitch]="scanResource.fileType">
              <span *ngSwitchCase="'GENERATE_DOCX'">{{"MicrosoftWord" | translate}}</span>
              <span *ngSwitchCase="'GENERATE_SEARCHABLE_PDF'">{{"Pdf" | translate}}</span>
              <span *ngSwitchDefault>{{"UnknownFileType" | translate}}</span>
            </div>
            <div>
              <span am-time-ago="scanResource.created">{{"Created" | translate}} {{scanResource.created | amLocale: currentLanguage | amTimeAgo}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /ScanJobs -->
  </div> <!-- /Scans -->
</ion-content>
<!-- Pagination -->
<div class="pagination-container scan-list" *ngIf="scanResources['pagingObject'] && scanResources['pagingObject'].totalPages > 0">
  <button class="btn btn-sm prev" (click)="currentPage > 0 ? getNextPage(true) : false" [disabled]="scanResources['pagingObject'].number === 0">
    <i class="icon-left-open"></i>
  </button>
  <span class="paging-numerics">{{scanResources['pagingObject'].number + 1}}/{{scanResources['pagingObject'].totalPages}}</span>
  <button class="btn btn-sm next" [disabled]="currentPage + 1 >= scanResources['pagingObject'].totalPages" (click)="getNextPage()">
    <i class="icon-right-open"></i>
  </button>
</div>
<!-- /Pagination -->
